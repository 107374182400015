import React from 'react'
import './Nav.scss'
function Nav() {
  return (
    <nav>
    <h1>Custom Wordle</h1>
  </nav>
  )
}

export default Nav